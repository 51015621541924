import { Grid, Stack } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import { availableCreditSelectors } from '@src/data/store/AvailableCredit/available-credit-store'
import availableCreditEffects from '@src/data/store/AvailableCredit/available-credit-effects'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Breadcrumb, InputTextField, PageError, PageSpinner } from '../../../components'
import PendingMessage from '../../../components/PendingMessage'
import { useAppDispatch, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { documentSelectors } from '../../../data/store/Document'
import { userSelectors } from '../../../data/store/UserStore'
import {
  BaseWorksheetFundingThresholdExtend,
  EFinancingProgram,
  EWorksheetDecision,
  NormsMessage,
  WorksheetDecision,
} from '../../../data/types'
import WarningMessageAccordion from '../../ViewCreditApplicationPage/components/warningMessageAccordion'
import LoanSummaryBox from '../components/LoanSummaryBox'
import WorksheetDecisionDialog from '../components/WorksheetDecisionDialog'
import WorksheetDecisionSection from '../components/WorksheetDecisionSection'
import { fullCreditApplicationEffects } from '../../../data/store/FullCreditApplication'
import { b2cWorksheetActions, b2cWorksheetSelectors } from '../../../data/store/B2cWorksheet/b2c-worksheet-store'
import { b2cWorksheetEffects } from '../../../data/store/B2cWorksheet'
import { B2cWorksheetDecisionDTO } from '../../../data/types/B2cWorksheetSchema'
import TransactionSection from '../components/TransactionSection'
import { useDialogDecisionDefaultValue } from '../viewWorksheet-hooks'
import { canComputeFunding, getEmptyComputedFundingDto } from '../viewWorksheet-selectors'

const B2cWorksheetViewPage = () => {
  const { t } = useTranslation()

  const [currentOpenDialog, setCurrentOpenDialog] = React.useState<string>('')

  const dispatchEffect = useSideEffect()

  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const error = useAppSelector(appSelectors.getBusinessError)
  const commonNormsMessage: NormsMessage[] = useAppSelector(creditSelectors.getCurrentCreditApplicationSpecificNorms)
  const b2cWorksheet = useAppSelector(b2cWorksheetSelectors.getCurrent)
  const computedInfo = useAppSelector(b2cWorksheetSelectors.getComputedInfo)

  const availableCredit = useAppSelector(availableCreditSelectors.getAvailableCredit)
  const creditInProgress = useAppSelector(availableCreditSelectors.getCreditInProgress)

  const totalObligation = useAppSelector(b2cWorksheetSelectors.getTotalObligation)
  const user = useAppSelector(userSelectors.getUser)
  const worksheetNormWarningMessages = useAppSelector(b2cWorksheetSelectors.getCurrentWorksheetNormWarningMessages)

  const areAllTasksRequiredForCVTCompleted = useAppSelector(creditSelectors.areAllTasksRequiredForCVTCompleted)
  const areAllDocumentsRequiredForCVTApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredForCVT)
  const confirmAllIncomesStatus = useAppSelector(creditSelectors.getConfirmAllIncomesStatus)
  const finalDecisionIsPending = useAppSelector(creditSelectors.isFinalDecisionPending)

  const dialogDecisionDefaultValue = useDialogDecisionDefaultValue(
    creditApplication!,
    b2cWorksheet!,
    areAllTasksRequiredForCVTCompleted,
    areAllDocumentsRequiredForCVTApproved,
    confirmAllIncomesStatus!,
  )

  const editDisabled = creditApplication?.editLocked || finalDecisionIsPending

  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.B2c}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  const EditDecisionDialogOpen = 'EditDecisionDialogOpen'

  React.useEffect(() => {
    if (creditApplication?.id) {
      dispatchEffect(
        availableCreditEffects.getAvailableCredit(creditApplication.id, creditApplication.financingProgramId),
      ).catch(reportErrorToConsole)
    }
  }, [creditApplication, dispatchEffect])

  React.useEffect(() => {
    if (creditApplication && b2cWorksheet && Object.keys(computedInfo).length === 0) {
      const interestRate = creditApplication.finalCreditDecision.interestRate ?? 0
      const stateIso = creditApplication.applicant.currentAddress.stateIso
      const requestId = nanoid()

      const computeDto: FundingInfoDto = {
        financingProgramId: creditApplication.financingProgramId,
        amountRequested: b2cWorksheet.amountRequested ?? 0,
        includeInsurance: b2cWorksheet.includeInsurance,
        paymentFrequency: b2cWorksheet.paymentFrequency,
        paymentPlanId: b2cWorksheet.paymentPlanId,
        term: b2cWorksheet.term,
        interestRate,
        hasCoApplicant: false,
        stateIso: stateIso!,
        deliveryOn: b2cWorksheet.deliveryOn,
        firstPaymentOn: b2cWorksheet.firstPaymentOn,
        requestId,
        creditApplicationId: b2cWorksheet.id,
        merchantId: creditApplication.merchantId,
      }

      if (canComputeFunding(computeDto)) {
        dispatchEffect(b2cWorksheetEffects.computeB2cFunding(computeDto)).catch(reportErrorToConsole)
      } else {
        const emptyComputedDto = getEmptyComputedFundingDto()

        dispatch(b2cWorksheetActions.setFundingComputed(emptyComputedDto))
      }
    }
  }, [b2cWorksheet, computedInfo, creditApplication, dispatch, dispatchEffect])

  // callbacks
  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [])

  const handleSaveDecision = useCallback(
    (data: WorksheetDecision) => {
      if (b2cWorksheet) {
        const updatedFinalDecision = {
          creditApplicationId: b2cWorksheet.creditApplicationId,
          ...b2cWorksheet.finalDecision,
          versionTag: b2cWorksheet.versionTag,
          fundingThresholdExtend: null,
          decision: data.decision,
          otherReason: data.otherReason,
        } as B2cWorksheetDecisionDTO

        if (data.decision === EWorksheetDecision.ApprovedWithCondition) {
          const fundingThresholdExtend: BaseWorksheetFundingThresholdExtend = {
            maxPmtAmount: data.maxPmtAmount,
            maxTotalAmountFinanced: data.maxTotalAmountFinanced,
            maxTermDuration: data.maxTermDuration,
          }
          updatedFinalDecision.fundingThresholdExtend = fundingThresholdExtend
        }

        dispatchEffect(
          b2cWorksheetEffects.updateDecision({
            ...updatedFinalDecision,
            creditApplicationId: b2cWorksheet?.creditApplicationId,
          }),
        )
          .then(() => {
            closeDialogs()
            return dispatchEffect(
              fullCreditApplicationEffects.getById(b2cWorksheet?.creditApplicationId, EFinancingProgram.B2c),
            )
          })
          .catch(reportErrorToConsole)
      }
    },
    [b2cWorksheet, closeDialogs, dispatchEffect],
  )

  return (
    <div>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && b2cWorksheet && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          <Stack>
            <PageError errors={error} />
            {finalDecisionIsPending && <PendingMessage />}
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <InputTextField
                value={
                  creditApplication?.finalCreditDecision.planName
                    ? `${creditApplication.finalCreditDecision.planName}`
                    : ''
                }
                label={t('worksheetCommon.program')}
                disabled
              />
              <InputTextField
                label={t('worksheetCommon.creditLimit')}
                disabled
                value={creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0}
              />
              <InputTextField label={t('worksheetCommon.creditInProgress')} disabled value={creditInProgress} />

              <InputTextField label={t('worksheetCommon.availableCredit')} disabled value={availableCredit} />
            </Stack>
            {commonNormsMessage?.length > 0 && (
              <WarningMessageAccordion
                applicantCreditWarnings={null}
                coapplicantCreditWarnings={null}
                commonCreditWarnings={commonNormsMessage}
                worksheetWarnings={null}
                expanded={false}
              />
            )}
            <Grid container textAlign="center" justifyContent="space-between" p={4}>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <LoanSummaryBox worksheet={b2cWorksheet} computedInfo={computedInfo} merchantPaymentPlan={null} />
              </Grid>
              <Grid item xs={12} md={4} border="1px solid" borderRadius={2} p={4}>
                <WorksheetDecisionSection
                  worksheet={b2cWorksheet}
                  onClick={() => setCurrentOpenDialog(EditDecisionDialogOpen)}
                  editDisabled={editDisabled}
                  userCanEditWorksheet={user?.rights.canEditWorksheet === true}
                />
              </Grid>
            </Grid>
            <TransactionSection
              worksheet={b2cWorksheet}
              creditApplication={creditApplication}
              computedInfo={computedInfo}
              totalObligation={totalObligation}
              merchantPaymentPlan={null}
              paymentMerchantsByIds={{}}
            />
          </Stack>

          <WorksheetDecisionDialog
            open={currentOpenDialog === EditDecisionDialogOpen}
            title={t('worksheet.decision')}
            onCancel={closeDialogs}
            onConfirm={handleSaveDecision}
            worksheetWarnings={worksheetNormWarningMessages}
            defaultValue={dialogDecisionDefaultValue}
            worksheet={b2cWorksheet}
            creditapplication={creditApplication}
          />
        </>
      )}
    </div>
  )
}

export default B2cWorksheetViewPage
