import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import { compareAsc } from 'date-fns'
import { debounce } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { canComputeFunding, getEmptyComputedFundingDto } from '@src/containers/ViewWorksheet/viewWorksheet-selectors'
import { availableCreditSelectors } from '@src/data/store/AvailableCredit/available-credit-store'
import availableCreditEffects from '@src/data/store/AvailableCredit/available-credit-effects'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { Breadcrumb, DatePicker, InputTextField, PageError, PageSpinner, SelectComponent } from '../../../components'
import { useAppDispatch, useAppSelector } from '../../../data/store'
import { appActions, appSelectors } from '../../../data/store/AppStore'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { EFinancingProgram, EProvince, EWorksheetStatus, SelectValueListItem } from '../../../data/types'
import { FormatCurrency, formatDate, normalizeNumber } from '../../../services/Formatter'
import { reportErrorToConsole } from '../../../services/error-logger'
import MerchantPaymentInstructions from '../../ViewCreditApplicationPage/components/MerchantPaymentInstructions'
import { fullCreditApplicationEffects } from '../../../data/store/FullCreditApplication'
import { EditB2cWorksheetDto, EditB2cWorksheetDtoSchema } from '../../../data/types/B2cWorksheetSchema'
import { b2cWorksheetActions, b2cWorksheetSelectors } from '../../../data/store/B2cWorksheet/b2c-worksheet-store'
import { FundingInfoDto } from '../../../data/types/FundingInfoDto'
import { b2cWorksheetEffects } from '../../../data/store/B2cWorksheet'
import {
  aprThresholds,
  useAprTooHigh,
  useCantSelectDate,
  useFirstPaymentDateOptions,
  usePaymentPlanList,
  usePossibleTerms,
} from '../editWorksheet-hooks'

const EditB2cWorksheetPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const creditApplication = useAppSelector(creditSelectors.getCurrent)

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EditB2cWorksheetDto>({
    mode: 'onBlur',
    defaultValues: {} as EditB2cWorksheetDto,
    resolver: yupResolver(EditB2cWorksheetDtoSchema),
  })

  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect()
  const availableCredit = useAppSelector(availableCreditSelectors.getAvailableCredit)
  const creditInProgress = useAppSelector(availableCreditSelectors.getCreditInProgress)
  const isLoading = useAppSelector(b2cWorksheetSelectors.isSavingB2cWorksheet)
  const isLoadingCreditApplication = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const isComputingFunding = useAppSelector(b2cWorksheetSelectors.isComputeB2cFunding)
  const error = useAppSelector(appSelectors.getBusinessError)
  const merchant = useAppSelector(creditSelectors.getMerchant)
  const b2cWorksheet = useAppSelector(b2cWorksheetSelectors.getCurrent)
  const computedInfo = useAppSelector(b2cWorksheetSelectors.getComputedInfo)
  const totalFees = useAppSelector(b2cWorksheetSelectors.getTotalfees)
  const totalInsurance = useAppSelector(b2cWorksheetSelectors.getTotalInsurance)
  const listHolidays = useAppSelector(appSelectors.getHolidays)
  const paymentPlanList = usePaymentPlanList(creditApplication?.createdOn, [])
  const possibleTerms = usePossibleTerms(creditApplication?.finalCreditDecision.maxTermDuration)
  const cantSelectDate = useCantSelectDate(listHolidays)
  const computeFirstPaymentDateOptions = useFirstPaymentDateOptions(listHolidays)
  const aprTooHigh = useAprTooHigh(computedInfo, creditApplication)

  const firstPaymentOn = watch('firstPaymentOn')
  const [firstPaymentDateOptions, setFirstPaymentOptions] = useState<Date[]>([])

  const easternTime = new Date().toLocaleString('en-US', { timeZone: 'America/Toronto' })
  const currentDateAsEasternTimeZone = new Date(easternTime)

  // dynamic values
  const amountRequested = normalizeNumber(watch('amountRequested')?.toString()) ?? 0
  const paymentFrequency = watch('paymentFrequency')
  const paymentPlanId = watch('paymentPlanId')
  const deliveryOn = watch('deliveryOn')
  const term = watch('term')
  const includeInsurance = watch('includeInsurance')
  const provinceSupportsInsurance =
    creditApplication?.applicant.currentAddress.stateIso &&
    creditApplication.applicant.currentAddress.stateIso !== EProvince.quebec &&
    creditApplication.applicant.currentAddress.stateIso !== EProvince.saskatchewan
  const totalObligation = amountRequested + (computedInfo?.totalInterestAmount ?? 0) + totalFees + totalInsurance

  useEffect(() => {
    if (creditApplication?.id) {
      dispatchEffect(
        availableCreditEffects.getAvailableCredit(creditApplication.id, creditApplication.financingProgramId),
      ).catch(reportErrorToConsole)
    }
  }, [creditApplication, dispatchEffect])

  // reset form on worksheet changed
  useEffect(() => {
    if (b2cWorksheet) {
      reset(b2cWorksheet as EditB2cWorksheetDto)
      setValue('firstPaymentOn', b2cWorksheet.firstPaymentOn ?? new Date())
    }
  }, [b2cWorksheet, merchant, reset, setValue])

  const computeFunding = useCallback(
    (computeDto: FundingInfoDto) => {
      return dispatchEffect(b2cWorksheetEffects.computeB2cFunding(computeDto))
    },
    [dispatchEffect],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceComputeFunding = useCallback(debounce(computeFunding, 500), [computeFunding])

  // recompute funding
  useEffect(() => {
    if (creditApplication && id && merchant) {
      const interestRate = creditApplication.finalCreditDecision.interestRate ?? 0
      const stateIso = creditApplication.applicant.currentAddress.stateIso!
      const requestId = nanoid()

      const computeDto: FundingInfoDto = {
        financingProgramId: creditApplication?.financingProgramId,
        amountRequested,
        includeInsurance,
        paymentFrequency,
        paymentPlanId,
        term,
        interestRate,
        hasCoApplicant: false,
        stateIso,
        deliveryOn,
        firstPaymentOn,
        requestId,
        creditApplicationId: id,
        merchantId: merchant.id,
      }
      if (canComputeFunding(computeDto)) {
        debounceComputeFunding(computeDto)?.catch(reportErrorToConsole)
      } else {
        const emptyComputedDto = getEmptyComputedFundingDto()

        dispatch(b2cWorksheetActions.setFundingComputed(emptyComputedDto))
      }
    }
  }, [
    amountRequested,
    paymentFrequency,
    paymentPlanId,
    deliveryOn,
    firstPaymentOn,
    term,
    includeInsurance,
    creditApplication,
    b2cWorksheet,
    debounceComputeFunding,
    dispatch,
    id,
    merchant?.id,
    merchant,
  ])

  // recompute first payment options and date
  useEffect(() => {
    const options = computeFirstPaymentDateOptions(deliveryOn)
    setFirstPaymentOptions(options)
    const currentValues = getValues()
    const currentFirstPaymentDateAsString = formatDate(currentValues.firstPaymentOn)
    const availableDatesAsString: string[] = []
    options.forEach((item) => {
      availableDatesAsString.push(formatDate(item))
    })

    if (availableDatesAsString.length > 0 && !availableDatesAsString.includes(currentFirstPaymentDateAsString)) {
      setValue('firstPaymentOn', options[0])
    }
  }, [deliveryOn, computeFirstPaymentDateOptions, getValues, setValue])

  const onFormSubmitted = (data: EditB2cWorksheetDto) => {
    const expiry = new Date(creditApplication!.expiresOn)
    const receivedOnPlus90Days = new Date(creditApplication!.applicant.hardHitReport.receivedOn)
    receivedOnPlus90Days.setDate(receivedOnPlus90Days.getDate() + 90)
    const deliveryOnDate = new Date(`${getValues('deliveryOn')}T20:00:00`) // 20PM Local Time
    const deliveryOnUTC = new Date(`${getValues('deliveryOn')}T00:00:00`)
    const deliveryOnDateAsEasternTimeZone = new Date(
      deliveryOnDate.toLocaleString('en-US', { timeZone: 'America/Toronto' }),
    )
    const baseActivationDateAsEasternTimeZone = new Date(
      currentDateAsEasternTimeZone.getFullYear(),
      currentDateAsEasternTimeZone.getMonth(),
      currentDateAsEasternTimeZone.getDate(),
      0,
      0,
      0,
    )

    if (compareAsc(deliveryOnDateAsEasternTimeZone, baseActivationDateAsEasternTimeZone) === -1) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateInPast' }]))
    } else if (cantSelectDate(deliveryOnDateAsEasternTimeZone)) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateIsHoliday' }]))
    } else if (amountRequested > availableCredit) {
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.amountRequestedHigherThanAvailableCredit' }]))
    } else if (compareAsc(deliveryOnUTC, receivedOnPlus90Days) === 1)
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.activationDateReport90Days' }]))
    else if (compareAsc(deliveryOnUTC, expiry) === 1)
      dispatch(appActions.setBusinessErrors([{ message: 'worksheetCommon.deliveryOnEarlierExpiration' }]))
    else {
      data.amountRequested = amountRequested
      data.status = EWorksheetStatus.Active
      dispatchEffect(b2cWorksheetEffects.update(data))
        .then(() =>
          dispatchEffect(fullCreditApplicationEffects.getById(id!, EFinancingProgram.B2c)).then(() =>
            navigate(`/Applications/${EFinancingProgram.B2c}/${id}/view`, { replace: true }),
          ),
        )
        .catch(reportErrorToConsole)
    }
  }

  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.B2c}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  return (
    <div>
      <PageSpinner withBackdrop isLoading={isLoading && isLoadingCreditApplication} />
      {!isLoading && (
        <Stack>
          <PageError errors={error} />
          {creditApplication && aprTooHigh && (
            <Alert severity="error">
              {t('common.errors.aprTooHigh', '', {
                threshold: aprThresholds[creditApplication.applicant.currentAddress.stateIso!],
                stateIso: t(`enum.eProvince.${creditApplication.applicant.currentAddress.stateIso!}`),
              })}
            </Alert>
          )}
        </Stack>
      )}
      <Breadcrumb trees={breadCrumbs} />
      <form onSubmit={handleSubmit(onFormSubmitted, reportErrorToConsole)}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <InputTextField
            value={
              creditApplication?.finalCreditDecision.planName ? `${creditApplication.finalCreditDecision.planName}` : ''
            }
            label={t('worksheetCommon.program')}
            disabled
          />
          <InputTextField
            label={t('worksheetCommon.creditLimit')}
            disabled
            value={creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0}
          />
          <InputTextField label={t('worksheetCommon.creditInProgress')} disabled value={creditInProgress} />
          <InputTextField label={t('worksheetCommon.availableCredit')} disabled value={availableCredit} />
          <div style={{ marginTop: '1%' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={aprTooHigh || isComputingFunding || !computedInfo || isLoading}
            >
              {t('common.save')}
            </Button>
          </div>
        </Stack>
        {merchant && <MerchantPaymentInstructions merchant={merchant} />}
        <Grid container marginTop={5}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <InputTextField
                  InputProps={{
                    endAdornment: '$',
                  }}
                  {...register('amountRequested')}
                  error={errors?.amountRequested}
                  label={t('worksheetCommon.amountRequested')}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <DatePicker
                  name="deliveryOn"
                  control={control}
                  label={t('worksheetCommon.activationDate')}
                  error={errors?.deliveryOn}
                  disablePast
                  minDate={currentDateAsEasternTimeZone}
                  shouldDisableDate={cantSelectDate}
                  disabled={creditApplication?.editLocked}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={firstPaymentDateOptions.map((item) => {
                    const data: SelectValueListItem = {
                      label: formatDate(item),
                      value: formatDate(item),
                    }
                    return data
                  })}
                  label={t('worksheetCommon.firstPaymentDate') as string}
                  {...register('firstPaymentOn')}
                  error={errors?.firstPaymentOn}
                  value={formatDate(firstPaymentOn)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={paymentPlanList}
                  label={t('worksheet.paymentPlan') as string}
                  {...register('paymentPlanId')}
                  error={errors?.paymentPlanId}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={[{ label: 'worksheet.monthly', value: 'monthly' }]}
                  label={t('worksheet.paymentFrequency') as string}
                  {...register('paymentFrequency')}
                  error={errors?.paymentFrequency}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <SelectComponent
                  items={possibleTerms.map((item) => {
                    const data: SelectValueListItem = {
                      label: `${item} ${t('worksheet.month')}`,
                      value: item,
                    }
                    return data
                  })}
                  label={t('worksheet.term') as string}
                  {...register('term')}
                  error={errors?.term}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  {...register('includeInsurance')}
                  control={<Checkbox defaultChecked={b2cWorksheet?.includeInsurance} />}
                  label={t('worksheetCommon.addInsurance')}
                  disabled={!provinceSupportsInsurance}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                border: 1,
                padding: 2,
                marginLeft: 5,
                paddingBottom: 10,
              }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('worksheetCommon.fundingSummary')} :
                </Typography>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.loanAmount')} :</Typography>
                  <Typography>{`${FormatCurrency(getValues('amountRequested'))}`}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {`${t('worksheetCommon.interest')} (${`${creditApplication?.finalCreditDecision?.interestRate}%`})`}
                    :{' '}
                  </Typography>
                  <Typography>{FormatCurrency(computedInfo?.totalInterestAmount)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    {`${t('worksheetCommon.financeFee')} (${computedInfo?.icebergLenderAdminFeeRate}%)`}:
                  </Typography>
                  <Typography>{FormatCurrency(totalFees)}</Typography>
                </Stack>

                {provinceSupportsInsurance && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>{t('worksheetCommon.insurance')} :</Typography>
                    <Typography>{FormatCurrency(totalInsurance)}</Typography>
                  </Stack>
                )}

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.totalObligation')} :</Typography>
                  <Typography>{FormatCurrency(totalObligation)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.annualPercentageRate')} :</Typography>
                  <Typography>{computedInfo.effectiveRate} %</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" paddingTop={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('worksheetCommon.payment')}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.maxPmtAmount')} :</Typography>
                  <Typography>{FormatCurrency(creditApplication?.finalCreditDecision.maxPmtAmount)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.paymentFrequency')} :</Typography>
                  <Typography>
                    {b2cWorksheet?.paymentFrequency
                      ? t(`worksheet.${b2cWorksheet?.paymentFrequency}`)
                      : t('worksheet.monthly')}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.frequencyAmount')} :</Typography>
                  <Typography>{FormatCurrency(computedInfo?.paymentForFrequency)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheetCommon.firstPaymentDate')} :</Typography>
                  <Typography>{formatDate(firstPaymentOn)}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between" paddingTop={3}>
                  <Typography fontWeight="bold">{t('worksheetCommon.amountToBePaid')} :</Typography>
                  <Typography>{FormatCurrency(getValues('amountRequested') ?? 0)}</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default EditB2cWorksheetPage
